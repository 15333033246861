import React from "react"
import { Link } from "gatsby"

import Layout from "../components/de/layout"
//import Image from "../components/image"
import SEO from "../components/de/seo"

import MainImg from "./../images/main_bg.jpg"

import shape1 from "./../images/dec/1.png"
import shape2 from "./../images/dec/2.png"
import shape3 from "./../images/dec/3.png"
import shape4 from "./../images/dec/4.png"
import shape5 from "./../images/dec/5.png"
import shape6 from "./../images/dec/6.png"
import shape7 from "./../images/dec/7.png"
import shape8 from "./../images/dec/8.png"

const Inspiracija = () => (
  <Layout>
    <SEO
      title="MOONAMAR - INSPIRATION AUS DER WEISHEIT DER NATUR"
      description="Exklusive Auswahl bester Geschenke aus der Schatzkammer der Natur in Form von MOONAMAR-Nahrungsergänzungsmitteln und Kosmetika."
    />

    <section
      id="_heroPage"
      className="hero is-large _inspiracija"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">INSPIRATION</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns">
              <div className="column _headline">
                <p className="content">
                  Jeder Mensch ist einzigartig in Bezug auf sein Aussehen, sein
                  Hormonsystem, sein Immunsystem, sowie in Bezug auf seine
                  emotionale und Charaktereigenschaften. Dementsprechend
                  entwickelt jeder Mensch unterschiedliche Vorlieben und
                  reagiert auf eigene Weise auf Reize aus seiner Umgebung. Aus
                  diesem Grund gilt das Gleiche bei der Auswahl der richtigen
                  Diät, Kosmetik, Nahrungsergänzungsmittel, Diätprodukte usw. Es
                  ist zu berücksichtigen, dass nicht jedes Präparat jedem
                  Stoffwechsel entspricht und dass nicht jeder Organismus auf
                  ein und dasselbe Präparat anspricht. Was einem wohltut, muss
                  nicht unbedingt auch anderen wohltun. Im Gegenteil, was einem
                  wohltut kann gelegentlich dem anderen sogar schaden.
                </p>
                <p className="content">
                  Einige naturliche Substanzen haben eine breite, fast
                  universelle Anwendung, wobei sich ihre wohltuende Wirkungen
                  fur viele Menschentypen geltend machen. Solche Naturstoffe
                  verdanken Ihre Tausende von Jahren alte Anwendung ihrer
                  besonderen Zusammensetzung, die reich an hochwertigen
                  Bestandteilen wie Proteinen, Aminosäuren, ungesättigten
                  Fettsäuren, Vitaminen, Mineralstoffen, Antioxidantien,
                  phenolischen Bestandteilen und dergleichen sind.
                </p>
                <p className="content">
                  MOONAMAR Team ist bestrebt, die besten und universellsten
                  Geschenke aus den Schätzen der Natur zu finden und Ihnen
                  anzubieten. Unser anliegen ist solche Zutaten und
                  Zutatenkombinationen zu selektieren, die sowohl in einer
                  langen Tradition der Nutzung als auch in der modernen
                  Wissenschaft ihre Unterstützung finden. Es liegt jedoch an
                  jedem Mensch, den Lebensstil, die Lebensmittel und die
                  Zubereitungen zu erkennen, die am besten zu ihm passen, die
                  ihm Energie geben und zur Erhaltung seiner Gesundheit
                  beitragen.{" "}
                </p>
                <p className="content">
                  <strong>
                    Zur Verfügung stellen wir Ihnen eine exklusive Auswahl der
                    hochqualitativen Produkte und Nahrungsergänzungsmitteln mit
                    wohltuenden Wirkungen. Nachhaltige Produktqualität, der
                    Fokus auf natürliche Zusammensetzung und Ihre Zufriedenheit
                    sind die Hauptziele unseres Expertenteams.
                  </strong>
                </p>
                <p className="content">
                  Für Fragen, Kommentare, Tipps und konstruktive Kritik stehen
                  wir Ihnen täglich über das Kontaktformular oder per E-Mail
                  unter <a href="mailto:info@moonamar.co">info@moonamar.co</a>{" "}
                  zur Verfügung..
                </p>
                <Link
                  to="/bestellformular"
                  className="button is-primary is-rounded is-small btn1head"
                >
                  SCHREIBE UNS
                </Link>
                <br />
                <br />
                <p className="content">Wir freuen uns auf Ihre Nachrichten!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default Inspiracija
